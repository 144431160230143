import React, { useEffect, useState } from "react";
import AOS from "aos";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getYouTubeVideoId } from "../../../common/commonHelpers";
import {
  BACKEND_BASE_URL,
  CLG_LIST,
  PORTAL_BACKEND_BASE_URL,
} from "../../../common/constant";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import InvertedComma from "../../../assets/image/white-quoter.png";

import WomenAvatar from "../../../assets/image/women avatar.jpg";
import BoyAvatar from "../../../assets/image/boyavatar.jpg";
import CareerlineLogo from "../../../assets/image/logo.png";
import RightAdvice from "../../../assets/image/rightadvice.png";
import Attend1 from "../../../assets/image/attend1.png";
import Attend2 from "../../../assets/image/attend2.png";
import Attend3 from "../../../assets/image/attend3.png";
import Attend4 from "../../../assets/image/attend4.png";
import USADEAN from "../../../assets/image/usa dean.jpeg";
import FUTUREPLAN from "../../../assets/image/future-plan.png";
import USAUniLogo1 from "../../../assets/image/universitylogo/usa/Depaul University.png";
import USAUniLogo2 from "../../../assets/image/universitylogo/usa/University of Nebrska Omaha.png";
import USAUniLogo3 from "../../../assets/image/universitylogo/usa/James Madison University.png";
import USAUniLogo4 from "../../../assets/image/universitylogo/usa/Lipscomb University.png";
import USAUniLogo5 from "../../../assets/image/universitylogo/usa/Long Island University.png";
import USAUniLogo6 from "../../../assets/image/universitylogo/usa/Towson University.png";
import USAUniLogo7 from "../../../assets/image/universitylogo/usa/University of Hartford.png";
import USAUniLogo8 from "../../../assets/image/universitylogo/usa/Florida Atlantic University.png";
import USAUniLogo9 from "../../../assets/image/universitylogo/usa/texas aandm university.jpg";
import USAUniLogo10 from "../../../assets/image/universitylogo/usa/Western Washington University.png";
import USAUniLogo11 from "../../../assets/image/universitylogo/usa/California State University San Marcos.png";
import { Element, Link } from "react-scroll";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";

const EVENT_BRANCHES = [
  {
    label: "Surat",
    value: 1,
  },
  {
    label: "Ahmedabad",
    value: 10,
  },
];

const USALEventLPMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const EventGUID = params?.StudentID;
  const Campaign = params?.Campaign;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [data, setData] = useState({});
  const [commonEventID, setCommonEventID] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignTypeList, setCampaignTypeList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: "",
    BranchID: "",
    CollegePreferences: "",
    ExamGivenID: "",
  });

  useEffect(() => {
    if (EventGUID) {
      const fetchStudentDetails = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/EventMainForm/StudentDetailsByGUID`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventGUID: EventGUID,
              }),
            }
          );
          const responseData = await response.json();
          if (responseData.Status === 200) {
            const result = responseData?.Data;
            setInitialValues({
              ...initialValues,
              FirstName: result?.FirstName,
              LastName: result?.LastName,
              EmailID: result?.EmailID,
              MobileNo: result?.MobileNo,
              CollegePreferences: result?.CollegePreferences,
              BranchID: result?.BranchID,
              ExamGivenID: result?.ExamGivenID,
            });
          } else {
            console.log("Failed to fetch Student Details");
          }
        } catch (error) {
          console.log("Error while fetching Student Details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStudentDetails();
    }
  }, [EventGUID]);

  useEffect(() => {
    scrollToTop();
    AOS.init();
    const fetchHomePage = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ CMSURL: "CommonEventID" }),
          }
        );
        const data = await response.json();
        if (data.Status === 200) {
          setCommonEventID(data?.Data?.Template);
        } else {
          console.log("Failed to fetch blogs");
        }
      } catch (error) {
        console.log("Error while fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePage();
    // return () => {
    //   dispatch(clearEventID());
    // };
  }, []);

  useEffect(() => {
    if (commonEventID) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/findbyid?id=${
              commonEventID
              // eventID
              // eventID || commonEventID
            }`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const Response = await response.json();
          const result = Response?.Data;
          const updatedVideoUrls = result?.VideoURL.map((item) => {
            const videoId = getYouTubeVideoId(item.VideoURL);
            return { VideoID: videoId, ...item };
          });
          setData({
            BannerImage: result?.BannerImage,
            SideBanner: result?.SideBanner,
            CMSHeader: result?.CMSHeader,
            CMSFooter: result?.CMSFooter,
            EventCourse: result?.EventCourse ? result?.EventCourse : [],
            EventContry: result?.EventContry ? result?.EventContry : [],
            TimeSlotList: result?.TimeSlotList ? result?.TimeSlotList : [],
            VideoURL: updatedVideoUrls ? updatedVideoUrls : [],
            RegisteredPercentage: result?.RegisteredPercentage
              ? result?.RegisteredPercentage
              : null,
            Campaign: Campaign,
            StudentID: EventGUID,
          });
        } catch (error) {
          setError(error?.message || "NetWrok Error");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
      // if (StudentID?.toLowerCase() === "add") {
      const getAllCampaigns = async () => {
        try {
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/CampaignEventWise`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventID: commonEventID,
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Network Error fetching campaign types");
          }
          const result = await response.json();
          const dataList = result?.Data;
          const finalResult = dataList?.map((campaign) => {
            return {
              value: campaign.ID,
              label: campaign.CampaignName,
              CampaignTypeID: campaign.CampaignTypeID,
              CampaignURL: campaign.CampaignURL,
            };
          });
          setCampaignList(finalResult || []);
        } catch (error) {
          console.error("Error fetching campaign:", error);
        }
      };
      const getAllCampaignTypes = async () => {
        try {
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/CampaignTypeEventWise`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventID: commonEventID,
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Network Error fetching campaign types");
          }
          const result = await response.json();
          const dataList = result?.Data;
          const finalResult = dataList?.map((campaign) => {
            return {
              value: campaign.CampaignTypeID,
              label: campaign.CampaignTypeName,
            };
          });
          setCampaignTypeList(finalResult || []);
        } catch (error) {
          console.error("Error fetching campaign:", error);
        }
      };
      getAllCampaigns();
      getAllCampaignTypes();
      // }
    }
  }, [commonEventID]);
  // }, [eventID]);
  // }, [eventID, homeData]);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-prev-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-next-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowRightLong}
        />
      </div>
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonialData = [
    {
      img: `${WomenAvatar}`,
      description:
        "This event was a game-changer! I received a scholarship and fee waiver for my application to a top USA university. Don’t miss it!",
      name: "Priya Shah",
      background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
    },
    {
      img: `${BoyAvatar}`,
      description:
        "The direct interaction with university representatives helped me understand the admission process better. Highly recommended!",
      name: "Amit Patel",
      background: "linear-gradient(45deg, #c09500, goldenrod)",
    },
    {
      img: `${BoyAvatar}`,
      description:
        "Thank you CareerLine for organizing such event that I got opportunity to meet representative of universities & I got lot of guidence that helped me to choose better opportunities in my studies.",
      name: "Neel Patel",
      background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
    },
  ];

  const faqData = [
    {
      question: "Who Should Attend?",
      answer:
        "Students looking for undergraduate and graduate study opportunities in the USA.",
    },
    {
      question: "Is Registration Really Free?",
      answer:
        "Yes! There are no charges to attend this event. Just sign up and show up!",
    },
    {
      question: "Can I Apply to Multiple Universities?",
      answer:
        "Absolutely! This event features multiple universities and representatives to give you the best options.",
    },
  ];

  const RenderTestimonialCard = (props) => {
    return (
      <div key={props.uniqueKey} className="item pt-3">
        <div
          className="testimonials"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay={props.uniqueKey * 200}
          style={{ background: "white" }}
        >
          <div
            className="testimonials-item-top usaevent-testimonial-top p-3 position-relative"
            style={{ background: props?.background }}
          >
            <div
              className="testimonials-item-effect"
              style={{ background: props.effectColor }}
            ></div>
            <div className="testimonials-item-desc usaevent_testimonial">
              <img
                src={InvertedComma}
                style={{ marginRight: 25, display: "inline" }}
              />
              {props.description}{" "}
              <img
                src={InvertedComma}
                style={{
                  marginLeft: 25,
                  display: "inline",
                  transform: "rotate(180deg)",
                }}
              />
            </div>
          </div>
          <div className="testimonials-item-bottom  p-3">
            <div className="textimonial-img">
              <img src={props.img} alt="Student img" className="img-fluid" />
            </div>
            <div
              style={{
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                height: 48,
              }}
            >
              <span className="fw-bold" style={{ fontSize: 15 }}>
                {props.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const phoneRegExp = /^[0-9]{6,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
    // CollegePreferences: Yup.number().required("College Preference is required"),
    // ExamGivenID: Yup.number().required("Exam Given is required"),
    BranchID: Yup.number().required("Exam Given is required"),
  });

  const onSubmit = async (values) => {
    console.log("values", values);
    if (EventGUID) {
      const editPayload = {
        EventID: commonEventID ? +commonEventID : null,
        EventGUID: EventGUID,
        FirstName: values?.FirstName ? values?.FirstName : "",
        LastName: values?.LastName ? values?.LastName : "",
        EmailID: values?.EmailID ? values?.EmailID : "",
        MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
        BranchID: values?.BranchID ? +values?.BranchID : null,
        ExamGivenID: values?.ExamGivenID ? +values?.ExamGivenID : null,
        CountryPreference: "1",
        CollegePreferences: values?.CollegePreferences
          ? values?.CollegePreferences
          : "",
      };
      try {
        setLoading(true);
        const response = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/EventMainForm/UpdateEventMainForm`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editPayload),
          }
        );
        const responseData = await response.json();
        console.log("responseData", responseData);
        if (responseData.Status === 200) {
          SuccessToast(responseData?.Details);
          // SuccessToast(imageResponseData?.Details);
          navigate("/events/thankyou/");
        } else {
          ErrorToast(responseData?.Details);
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    } else {
      const insertPayload = {
        EventID: commonEventID ? +commonEventID : null,
        FirstName: values?.FirstName ? values?.FirstName : "",
        LastName: values?.LastName ? values?.LastName : "",
        EmailID: values?.EmailID ? values?.EmailID : "",
        MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
        BranchID: values?.BranchID ? +values?.BranchID : "",
        CountryPreference: "1",
        ExamGivenID: values?.ExamGivenID ? +values?.ExamGivenID : "",
        CollegePreferences: values?.CollegePreferences
          ? values?.CollegePreferences
          : "",
        Campaign: Campaign
          ? Campaign === "walkin"
            ? values?.Campaign
            : Campaign
          : "website",
      };
      console.log("insertPayload", insertPayload);
      try {
        setLoading(true);
        const response = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/EventMainForm/AddEventMainForm`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(insertPayload),
          }
        );
        const responseData = await response.json();
        console.log("responseData", responseData);
        if (responseData.Status === 200) {
          // setLoading(false);
          navigate("/events/thankyou/");
          SuccessToast(responseData?.Details);
          // setShowSuccessToast(false);
          // SuccessToast(imageResponseData?.Details);
        } else {
          ErrorToast(responseData?.Details);
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {/* <div>
        <CommonEventBannerSection data={data} />
      </div> */}
      <div className="event-banner-section">
        <div></div>
        <div className="event-banner-bottom">
          {/* <h1
            className="text-white "
            style={{ textShadow: "0 0 10px rgb(0 0 0 / 50%)" }}
          >
            Get admission to the Top United States Universities
          </h1>
          <p style={{ fontSize: 18, textShadow: "0 0 10px rgb(0 0 0 / 100%)" }}>
            Meet 10+ university representatives, unlock scholarships, fee
            waivers, and admissions tips at our exclusive event!
          </p> */}
          <div style={{ padding: "10px 0" }}>
            <Link
              activeClass="active"
              to="test1"
              offset={-120}
              duration={300}
              className="event-banner-btn"
            >
              Register For FREE
            </Link>
          </div>
        </div>
      </div>
      <div className="event-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="event-hero-label">Event Details</h3>
              <div className="row event-details-info mt-5">
                <p className="fw-bold">Where & when</p>
                <div className="row ">
                  <div className="col-md-6">
                    <h4 className="pb-3" style={{ color: "#DE5600" }}>
                      &nbsp;◙&nbsp;&nbsp;&nbsp;Surat
                    </h4>
                    <div className="d-flex gap-2 align-items-center mb-3 w-sm-50 w-100">
                      <i
                        className="bi bi-calendar-minus"
                        style={{ color: "#ff7a00", fontSize: 25 }}
                      ></i>
                      <p className="m-0">
                        <span className="fw-bold">Date & Time</span>
                        <br />
                        September 21, 2024 <br />
                        11:00 AM to 5.00 PM
                      </p>
                    </div>
                    <div className="d-flex gap-2 align-items-center w-sm-50 w-100">
                      <i
                        className="bi bi-geo-alt"
                        style={{ color: "#ff7a00", fontSize: 25 }}
                      ></i>
                      <p className="m-0">
                        <span className="fw-bold">Location</span>
                        <br />
                        Marriott Hotel, Dumas RD, Athwalines, Ambika Niketan,
                        Surat.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4 mt-md-0">
                    <h4 className="pb-3" style={{ color: "#DE5600" }}>
                      &nbsp;◙&nbsp;&nbsp;&nbsp;Ahmedabad
                    </h4>
                    <div className="d-flex gap-2 align-items-center mb-3 w-sm-50 w-100">
                      <i
                        className="bi bi-calendar-minus"
                        style={{ color: "#ff7a00", fontSize: 25 }}
                      ></i>
                      <p className="m-0">
                        <span className="fw-bold">Date & Time</span>
                        <br />
                        September 26, 2024 <br />
                        11:00 AM to 1.00 PM
                      </p>
                    </div>
                    <div className="d-flex gap-2 align-items-center w-sm-50 w-100">
                      <i
                        className="bi bi-geo-alt"
                        style={{ color: "#ff7a00", fontSize: 25 }}
                      ></i>
                      <p className="m-0">
                        <span className="fw-bold">Location</span>
                        <br />
                        Careerline Education, 601-608, AWS-III Block-A, Nr.
                        Manav Mandir, Memnagar Ahmedabad.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 mt-5">
              <h4
                className="mt-sm-1 mb-2 text-start"
                style={{
                  // textAlign: "center",
                  color: "rgb(220 86 0)",
                  // fontWeight: 700,
                }}
              >
                80+ University/college participants
              </h4>
            </div> */}
          </div>
        </div>
      </div>
      <div className="event-meet-section">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center">
              <div className="pe-0 pe-md-3">
                <h3 className="event-meet-label">Meet The Dean</h3>
                <div className="mt-5" style={{ textAlign: "justify" }}>
                  <p>
                    Meet and interact with Godfrey Gibbison, Dean of Extended
                    Learning and Global Programs, California State University,
                    San Marcos
                  </p>
                  <ul className="ms-3 custom-list">
                    <li>
                      Gain valuable insights into the admission process and what
                      top USA universities look for in applicants.
                    </li>
                    <li>
                      Get your questions about study programs, scholarships, and
                      application strategies answered.
                    </li>
                    <li>
                      Connect directly with a key decision-maker and expand your
                      network for future opportunities.
                    </li>
                  </ul>
                </div>
              </div>
              <img src={USADEAN} height={280} style={{ borderRadius: 5 }} />
            </div>
            <div className="col-12 mt-4 d-flex flex-column flex-md-row align-items-center ">
              <div style={{ width: "35%" }}>
                <img
                  src={FUTUREPLAN}
                  className="d-none d-md-block"
                  height={300}
                  style={{ borderRadius: 5 }}
                />
              </div>
              <div className="ps-0 ps-md-3">
                <h3 className="event-meet-label">What’s In It for You?</h3>
                <div className="mt-5" style={{ textAlign: "justify" }}>
                  <ul className="ms-3 custom-list">
                    <li>10+ Universities</li>
                    <li>Spot Assessments</li>
                    <li>
                      Application Fee Waivers <code>*</code>
                    </li>
                    <li>
                      Consultancy Fee Waiver for the students attending this
                      Seminar <code>*</code>
                    </li>
                    <li>
                      Discount on Coaching for IELTS/PTE <code>*</code>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-attend-section">
        <h2
          className="text-center mt-5 mb-2 text-white "
          style={{ fontWeight: 500 }}
        >
          Why Attend?
        </h2>
        <div className="container mb-5">
          <div className="row mb-5">
            <div className="col-12">
              <p className="text-white text-center fw-600">
                Navigating the path to USA education can be complex. Why risk
                missing out on scholarships or paying unnecessary fees? At this
                event, we provide the guidance, insights, and support you need
                to maximize your chances of success.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex">
              <img src={Attend1} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Personalized Admission Guidance</h5>
                <p>
                  Meet with university experts who understand what makes a
                  standout application.
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <img src={Attend2} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Learn About English Proficiency Tests</h5>
                <p>
                  Get tips and insights on acing IELTS/PTE and securing top
                  scores
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <img src={Attend3} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Secure Your Spot</h5>
                <p>
                  Reserve your place at the event for FREE and step closer to
                  your American dream!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-uni-section">
        <div className="container">
          <div className="row">
            <Element
              name="test1"
              className="col-lg-6 event-reg-form"
              id="event-registration-section"
            >
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12 d-flex">
                    <div
                      className="card flex-fill"
                      style={{ border: "1px solid goldenrod" }}
                    >
                      <div className="card-header event-card-header">
                        <h5 className="card-title mb-0">Basic Information</h5>
                      </div>
                      <div className="card-body">
                        <div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Branch<code>*</code>
                            </label>
                            <div className="col-lg-8 align-self-center">
                              <select
                                id="BranchID"
                                name="BranchID"
                                className={`form-control ${
                                  formik.errors.BranchID &&
                                  formik.touched.BranchID &&
                                  "error-border"
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.BranchID}
                              >
                                <option value={""} label={"Select Branch"} />
                                {EVENT_BRANCHES?.map((item) => {
                                  return (
                                    <option
                                      key={item?.value}
                                      value={item?.value}
                                      label={item?.label}
                                    />
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              First Name<code>*</code>
                            </label>
                            <div className="col-lg-8">
                              <input
                                name="FirstName"
                                type="text"
                                id="FirstName"
                                className={`form-control ${
                                  formik.errors.FirstName &&
                                  formik.touched.FirstName &&
                                  "error-border"
                                }`}
                                maxLength="50"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.FirstName}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Last Name<code>*</code>
                            </label>
                            <div className="col-lg-8">
                              <input
                                name="LastName"
                                type="text"
                                id="LastName"
                                className={`form-control ${
                                  formik.errors.LastName &&
                                  formik.touched.LastName &&
                                  "error-border"
                                }`}
                                maxLength="50"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.LastName}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Email Address<code>*</code>
                            </label>
                            <div className="col-lg-8">
                              <input
                                name="EmailID"
                                type="email"
                                id="EmailID"
                                className={`form-control ${
                                  formik.errors.EmailID &&
                                  formik.touched.EmailID &&
                                  "error-border"
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.EmailID}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Mobile No<code>*</code>
                            </label>
                            <div className="col-lg-8">
                              <input
                                name="MobileNo"
                                type="number"
                                id="MobileNo"
                                className={`form-control ${
                                  formik.errors.MobileNo &&
                                  formik.touched.MobileNo &&
                                  "error-border"
                                }`}
                                max={9999999999}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.MobileNo}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Exam Given<code>*</code>
                            </label>
                            <div className="col-lg-8 align-self-center">
                              <select
                                id="ExamGivenID"
                                name="ExamGivenID"
                                className={`form-control ${
                                  formik.errors.ExamGivenID &&
                                  formik.touched.ExamGivenID &&
                                  "error-border"
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.ExamGivenID}
                              >
                                <option
                                  value={""}
                                  label={"Select Exam Given"}
                                />
                                {data?.EventCourse?.map((item) => {
                                  return (
                                    <option
                                      key={item?.ID}
                                      value={item?.ID}
                                      label={item?.CourseName}
                                    />
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              College Preference<code>*</code>
                            </label>
                            <div className="col-lg-8 align-self-center">
                              <select
                                id="CollegePreferences"
                                name="CollegePreferences"
                                className={`form-control ${
                                  formik.errors.CollegePreferences &&
                                  formik.touched.CollegePreferences &&
                                  "error-border"
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.CollegePreferences}
                              >
                                <option
                                  value={""}
                                  label={"Select University"}
                                />
                                {CLG_LIST?.map((item) => {
                                  return (
                                    <option
                                      key={item?.value}
                                      value={item?.value}
                                      label={item?.label}
                                    />
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button
                            id="submit-btn"
                            className="submit-btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Submiting" : "Submit Details"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Element>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="row">
                <div className="col-12">
                  <h3 className="event-uni-label">
                    <span className="uni-count">10+</span> Partner Universities
                  </h3>
                </div>
                <div className="uni-list mt-5 mb-5">
                  <div className="row">
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo10}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo1}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo2}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo3}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo11}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo4}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo5}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo6}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo7}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo8}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="uni-img-div col-6 col-lg-4 mb-3">
                      <img
                        src={USAUniLogo9}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Element
        name="test1"
        className="event-reg-form"
        id="event-registration-section"
      >
        <CommonModifiedEventRegForm
          data={data}
          CommonEventID={commonEventID}
          campaignList={campaignList}
          campaignTypeList={campaignTypeList}
          setLoading={setLoading}
        />
      </Element> */}
      <div className="event-testimonials">
        <div className="container pt-5 pb-5">
          <div className="row">
            <p className="fw-600">30000+ Success Stories</p>
            <div className="col-12 mb-5">
              <h3 className="event-testimonials-label">
                How Our Previous Event Transformed{" "}
                <span className="testimonial-hightlight">Lives</span>
              </h3>
            </div>
            <div className="testimonials-list ">
              <Slider className="owl-carousel popular owl-theme" {...settings}>
                {testimonialData.map((item, index) => (
                  <RenderTestimonialCard
                    key={index}
                    uniqueKey={index}
                    description={item?.description}
                    img={item?.img}
                    name={item?.name}
                    background={item?.background}
                    university={item?.university}
                    pragram={item?.pragram}
                    intake={item?.intake}
                    country={item?.country}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="event-faqs">
        <div className="container pt-5 pb-5">
          <div className="row">
            <h2 className="text-center" style={{ fontWeight: 500 }}>
              FAQs
            </h2>
            <div className="faqs-list mt-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faqData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-headingOne${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls="flush-collapseOne"
                      >
                        {item?.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-headingOne${index}`}
                      className={`accordion-collapse collapse  ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item?.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-footer">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-between pb-5 footer-imgs">
              <img src={CareerlineLogo} alt="logo" />
              <img src={RightAdvice} alt="logo" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-5">
              <h3>Contact us</h3>
              <div className="footer-top-para-right">
                <p className="pb-3">
                  <i className="bi bi-envelope" />
                  <a href="mailto:admin@careerline.org">admin@careerline.org</a>
                </p>
                <i className="bi bi-telephone-plus" />
                <a
                  href="tel:6358989872"
                  // contentEditable="false"
                  style={{ cursor: "pointer" }}
                >
                  +91-6358989872
                </a>
              </div>
              <div className="footer-socialmedia mt-4">
                <a
                  href="https://www.facebook.com/CareerlineEF/"
                  target="_blank"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://twitter.com/Careerlineef" target="_blank">
                  <i className="bi bi-twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/careerlineef/"
                  target="_blank"
                >
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/c/CareerlineEducationFoundation/"
                  target="_blank"
                >
                  <i className="bi bi-youtube" />
                </a>
                <a
                  href="https://www.instagram.com/careerlineef/"
                  target="_blank"
                >
                  <i className="bi bi-instagram" />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-9 ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12349.140012101447!2d72.53275668773627!3d23.045011391639978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x605d0c100abfcc1a!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1724236290085!5m2!1sen!2sin"
                width={"100%"}
                height={200}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default USALEventLPMainPage;
